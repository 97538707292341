/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

h1, h2, h3, h4, h5, h6,
ion-title,
ion-card-title,
ion-segment-button {
  font-family: var(--ion-font-family-head);
}

@media only print {
   #printout {
     width: auto;
     height: auto;
     overflow: visible;
   }
}

ion-segment-button {
  text-transform: capitalize;
}

.ohn-size-w-100 {
	width:100%;
}

.ohn-size-h-100 {
	height:100%;
}

.ohn-grid-list {
  ion-row {
    /*border-bottom: 1px solid var(--ion-color-light, #fefefe);*/
    ion-col {
      padding: 8px 16px;
    }
  }
}

.ohn-report-grid {
  ion-row {
    ion-col {
      padding: 16px 8px;
      color: var(--ion-text-color);
      font-size: 1.2rem;
    }
  }
}

.ohn-report-grid-header {
  background: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast);   
  margin: 1.5rem auto;
  ion-col {
    padding: 1em;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

ion-item-group {
	ion-item-divider {
		text-transform: uppercase;
	}
}

ion-item {
  --border-color: var(--ion-color-light, #fefefe);
}

.ohn-form {
  .label-stacked  {
    font-size: 1.3rem;
    transform: none;
    padding: 8px 0;
    font-weight: 600;
  }

  ion-label {
    font-size: 1.3rem !important;
  }

  ion-input,
  ion-datetime,
  ion-textarea,
  ion-select {
    /*--background: var(--ion-color-light, #e5e5e5);
    background: var(--ion-color-light, #e5e5e5);*/
    --padding-bottom: 16px !important;
    --padding-end: 16px !important;
    --padding-start: 16px !important;
    --padding-top: 16px !important;
    margin-bottom: 16px;
    border-radius: 16px;
    border: 1px solid var(--ion-color-primary);
    font-size: 1.3rem;
  }

  ion-select,
  ion-datetime {
    &.ohn-full-size {
      max-width: 100%;
      width: 100%;
    }
  }

  ion-item-divider {
    font-size: 1.4rem;
    color: var(--ion-text-color);
    border: none;
    padding: 1rem;
    font-weight: 500;
  }

  ion-radio-group {
    ion-item {
      font-size: 1.2rem;
    }
  }

  markdown {
    p {
      font-size: 1.3rem;
      padding: .5rem;
    }
    li {
      font-size: 1.3rem;
    }
  }
}

ion-popover {
  .popover-wrapper {
    .popover-content {
      width:80%;
      max-width:300px;
      ion-select-popover {
        
        ion-radio-group {
          ion-item {
            .sc-ion-label-md-h,
            .sc-ion-label-ios-h {
              white-space: normal !important;
            } 
          }
        }
      }
    }
  }
}

ion-list-header {
  font-size:18px;
}

ion-item {
  ion-row {
    width: 100%;
  }
}

.ohn-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ohn-panel-content {
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: rgba(255,255,255,.85);
    }
  }
}

.monthview-datetable {

  thead {
    display: none;
  }

  td {
    text-align: right;
    border: 1px solid var(--ion-color-light) !important;
      .i2c-template-cell-label {
        color: var(--ion-color-dark) !important;
      }

    &.monthview-primary-with-event {
      background-color: transparent !important;
      .i2c-template-cell-label {
        color: var(--ion-color-dark) !important;
      }
    }
    &.monthview-selected {
      background-color: rgba(var(--ion-color-secondary-rgb), .1) !important;
      .i2c-template-cell-label {
        color: var(--ion-color-secondary) !important;
      }
    }
    &.monthview-current {
      background-color: rgba(var(--ion-color-tertiary-rgb), .4) !important;
      .i2c-template-cell-label {
        color: var(--ion-color-tertiary) !important;
      }

      .ohn-i2c-event {
        background: rgba(var(--ion-color-primary-contrast-rgb), .25) !important;
        color: var(--ion-color-primary-contrast-rgb, #fff) !important;

        &.ohn-i2c-event-completed {
          background: rgba(184, 186, 185, 1) !important;
          color: #fff !important;
        }
      }
    }

    &.text-muted {
      .i2c-template-cell-label {
        color: var(--ion-color-medium) !important;
      }
    }
  }
}


.modal-fullscreen {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;  
  }
}

.placeholder {
  color: var(--ion-color-medium);
  padding: 1rem;
  box-sizing: border-box;

  ion-icon {
    font-size: 6rem;
  }
}


.ohn-flex-grid {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;

  .ohn-flex-grid-item-scroll {
    overflow-y: scroll;
    margin: 1rem;
    padding: 0 1rem;
    border-bottom: 1px solid var(--ion-color-secondary);
    & {
      scrollbar-width: thin;
      scrollbar-color: #f4f3f5 transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 2px;
    }
  }
}

@media only screen and (min-width: 769px) {
  
  .ohn-flex-grid {
    flex-direction: row;
    align-items: center;

    .ohn-flex-grid-item {
      flex:1;

      &.ohn-flex-grid-item-scroll {
        border: none;
      }
    }
  }

}

.ohn-action-sheet {
  padding: 1rem;
  border-radius: 1em;
  max-width: 420px;
  background: #fff;
  margin: 0 auto;
}

.ohn-app-overview {
  margin: 0 auto;
  max-width: 480px;

  a {
    text-decoration: none;
    color: var(--ion-color-secondary);
  }

  li {
    padding: .5rem 0;
  }
}

.calendar-event {
  position: static !important;
  width: 100% !important;
  height: auto !important;
  padding: 0 !important;
}

@media (max-width: 750px){
  .dayview-allday-label, .calendar-hour-column {
    width: 52px !important;
    height: 52px !important;
  }
}

.ohn-glassmorphed {
  background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 32px 0 rgb(0 0 0 / 13%);
    backdrop-filter: blur(24px);
    -webkit-backdrop-filter: blur(24px);
  /*border-radius: 1.2rem;
  border: 1px solid rgba( 255, 255, 255, 0.25 );*/
}

.ohn-toolbar {
  width: 84px;
  height: 100%;
  position: absolute;
  background: var(--ion-color-dark-contrast);

  .ohn-toolbar-back-button {
    display: block;
  }
}

.ohn-content {
  padding-left: 100px;
}

.ohn-widgets {
  /*background: linear-gradient(110deg, #e5ecff 60%, #FAF8FF 60%);*/
  /*background: url(assets/art/bg-mesh-7.jpg) center center no-repeat;*/

 /*background-image: radial-gradient(circle, #0f0c29, #000);
 background-image: linear-gradient(110deg, #f0eefd, #FEFEFF);*/
 background: url(/assets/art/bg-2.svg) center center no-repeat;
  border-radius: 2rem 0 0;
  padding: 1rem 0 2rem 1rem;

    background-size: cover;

  .ohn-widget {

    overflow: hidden;

    border-radius: 2rem 0 0 2rem;

    .ohn-widget-header {
      background: rgba(var(--ion-color-light-rgb), 0.9);
      ion-col {
        padding: .5rem 1rem;
      }
    }
  }
}

.ohn-grid-list {
  ion-row {
    &:nth-child(even) {
      background: rgba(var(--ion-color-light-rgb), .48);
    }
  }
}

.ohn-logo {
  margin: 0 1rem;
  width: var(--ohn-logo-width);
  max-height: 100%;
}

.ohn-avatar {
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background: var(--ion-color-primary);
    margin: 1rem auto;
    border-radius: 1em;
    color: var(--ion-color-secondary-contrast);
}

.ohn-calendar {
  .ohn-calendar-header {
    .ohn-calendar-title {
      padding: 1rem;
      h2 {
        margin: 0;
        font-size: 2rem;
        font-weight: 600;
      }
    }
    .ohn-calendar-subtitle {
      background: var(--ion-color-light);
      ion-col {
        padding: .5rem;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  .ohn-calendar-event {
    border-radius: .5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: .4rem;
    color:#212121;
    font-size: 1rem;
    span {
      display: inline-block;
      padding:.25rem .5rem;
      font-weight: 600;
      color: #fff;
      border-radius: .5rem;
    }
  }
}

.ohn-calendar-events-list-header {
        padding: .8rem 1.5rem;
      h2 {
        margin: 0;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: right;
      }
}


  .ohn-i2c-event-completed {
    opacity: .5;
    ion-label {
      text-decoration: line-through;
    }
    &.ohn-calendar-event-hkdatapoint {
      opacity: 1;
      ion-label {
        text-decoration: none;
      }
    }
  }

  .ohn-calendar-event-workout {
    background: rgb(0 188 212 / 13%);
    ion-icon {
      color:#00bcd4;
    }
    span {
      background: rgb(0 188 212 / 83%);
    }
  }
  .ohn-calendar-event-hkdatapoint {
    background: linear-gradient(45deg, #FE2926, #fa62a1);
    color: #fff!important;
    ion-icon,
    ion-label {
      color:#fff!important;
    }
    span {
      background: #fa62a1;
    }
  }
  .ohn-calendar-event-survey {
    background: rgb(0 150 136 / 13%);
    ion-icon {
      color:#009688;
    }
    span {
      background: rgb(0 150 136 / 83%);
    }
  }
  .ohn-calendar-event-meeting {
    background: rgb(103 58 183 / 13%);
    ion-icon,
    a {
      color:#673ab7;
    }
    span {
      background:  rgb(103 58 183 / 83%);
    }
  }
  .ohn-calendar-event-medication {
    background: rgb(33 150 243 / 13%);
    ion-icon,
    a {
      color:#2196f3;
    }
    span {
      background: rgb(33 150 243 / 83%);
    }
  }

ion-modal {
 /* --background: rgba( 255, 255, 255, 0.1 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );

  ion-backdrop {
    background: #fff;
  }
  */
  .modal-wrapper {
    border-radius: 0 !important;
  }
}

ion-content {
  &.ohn-widgets-container {
    --background: url('assets/art/bg-mesh-2.jpg') 0 0 no-repeat !important;
    background-size:cover !important;
  }
}

.ohn-i2c-event {
  &.selected-page {
    background: var(--ion-color-success) !important;
    color: #fff !important;
  }
}

.ohn-timer {
  h1 {
    font-size:3rem;
  }
}

.ohn-grid-col-icon {
  font-size: 2rem;
  text-align: center;
}

.ohn-seal{
  max-width: var(--ohn-seal-width);
  margin: 0 .5rem;
}

.ohn-background {
  background-color: var(--ion-color-primary-contrast, #fff);
}

app-patient-surveys-widget,app-single-patient-survey-widget {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  ion-item {
    min-width: 50vw;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;

    ion-label {
      font-weight: 600;
    }
  }

  ion-select,
  ion-input {
    font-size: 2rem;
  }

  ion-button {
    font-size: 2rem;
    text-transform: uppercase;
    min-width: 50vw;
    margin-bottom: 2rem;
  }

}

.ohn-widget-print-popover {
  .popover-wrapper {
    .popover-content {
      max-width:50vw;
    }
  }
}

app-external-link-element {
    ion-button {
      font-size: 3.5vw;
      min-height: 10vw;
      width: 98%;
      margin: 0 auto;
    }
}



.ohn-gradient{
  //background: radial-gradient(circle at top, #0f0c29, #000);
  background: radial-gradient(circle at top, #ffffff, rgb(59 182 255 / 10%));
}

.ohn-page {
  display: flex;
  height: 100%;
  width: 100%;
  background: var(--ion-color-primary);

  .ohn-page-content {
    width: 100%;
    border-radius: 2.5rem 0 0;
    box-sizing: border-box;
    padding: 1rem;

    &.ohn-page-content-corner-right {
      border-radius: 0 2.5rem 0 0;
    }
  }

  .ohn-page-title {
    align-self: center;

    h1 {
      margin: 0;
      padding: 1.2rem 4rem;
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .ohn-page-toolbar-top {
    display: flex;
    padding: 0 2rem;
    background: #0f0e29;
    align-items: center;

    ion-searchbar {
      --background: rgb(255 255 255 / 8%);
      --border-radius: 2rem;
      --cancel-button-color: rgb(255 255 255 / 25%);
      --color: #fff;
      --icon-color: #ffffff;
      --placeholder-color:  rgb(255 255 255 / 85%);
      width: 20rem;
    }

    .ohn-page-toolbar-top-end {
      margin-left: 3rem;
      --padding-start:2.5rem;
      --padding-end:2.5rem;
    }
  }

  .ohn-page-toolbar-side {
    display: flex;
    flex-flow: column;
    text-align: center;
    align-items: center;
    background: var(--ion-color-primary, #000);
    margin: 0 0 1rem;
    border-radius: 0 0 3rem 0;
    padding-bottom: 2rem;

    .ohn-page-toolbar-side-bottom {
      margin-top: auto;
    }
  }
}

.ohn-masked-overflow {
  -webkit-mask-image: linear-gradient(to bottom, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 95%, transparent 98%, transparent 100%);
  mask-image: linear-gradient(to bottom, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 95%, transparent 98%, transparent 100%);
  padding-bottom: 2rem;
  box-sizing: border-box;
}

.ohn-table-body {
  ion-row {
    /*border-bottom: 1px solid rgb(229 229 229 / 80%);*/
    font-size: 1rem;
    background: rgb(242 243 245 / 40%);
    border-radius: 1rem;
    margin: .5rem 0 0;

    ion-col {
      padding: 1rem .5rem;
      word-break: break-word;

      &.ohn-table-col-name {
        strong {
          cursor: pointer;

          &:hover {
            color: var(--ion-color-primary);
          }
        }
      }
    }

    &.ohn-table-header {
      position: sticky;
      top:  0;
      font-size: .8rem;
      font-weight: 600;
      align-items: center;
      padding: .5rem;
      background: rgba(242, 243, 245, 0.9);
      z-index: 1;

      ion-col {
        padding: 0;
        
        h6 {
          margin: 0;
        }

        ion-button {
          font-weight: bold;
        }
      }
    }
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}